<template>
  <div
    class="message d-flex align-items-start"
    :class="{
      'message-right': right,
    }"
  >
    <b-avatar class="message-avatar" :src="image" />
    <div class="message-body p-1 py-50 mx-1 bg-white">
      <div v-html="messageContent" />
      <div class="message-meta d-flex align-items-center justify-content-end">
        <span class="mr-25">
          {{ messageDate }}
        </span>
        <feather-icon
          v-if="showCheck"
          :class="{ 'text-info': isRead }"
          icon="CheckIcon"
          size="12"
        />
        <feather-icon
          v-if="showDoubleCheck"
          class="double-check"
          :class="{ 'text-info': isRead }"
          icon="CheckIcon"
          size="12"
        />
        <feather-icon
          v-if="showError"
          class="text-danger"
          icon="AlertCircleIcon"
          size="12"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from "bootstrap-vue";
import { formatDateStringToDate } from "@/utils/formatters";

export default {
  components: {
    BAvatar,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    right: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: null,
    },
  },
  computed: {
    messageContent() {
      return this.message?.content
        ? this.message.content.replace(/\n/g, "<br>")
        : "";
    },
    messageDate() {
      const messageDate = this.message?.sentAt || null;
      if (!messageDate) return null;
      return formatDateStringToDate(messageDate, this.$i18n.locale, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
      });
    },
    showCheck() {
      return ["SENT", "DELIVERED", "READ"].includes(this.message?.status);
    },
    showDoubleCheck() {
      return ["DELIVERED", "READ"].includes(this.message?.status);
    },
    isRead() {
      return this.message?.status === "READ";
    },
    showError() {
      return ["FAILED"].includes(this.message?.status);
    },
  },
};
</script>

<style lang="scss" scopped>
.message-right {
  flex-direction: row-reverse;
}
.message-body {
  border-radius: 0.357rem;
  box-shadow: 0 4px 8px 0 rgba(34, 41, 47, 0.12);

  .message-meta {
    margin-top: 10px;
    font-size: 10px;
  }

  .double-check {
    margin-left: -7px;
  }
}
</style>
