<template>
  <div>
    <!-- SEARCHBAR -->
    <div class="p-1 border-bottom searchbar">
      <b-input-group class="input-group-merge w-100 round">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" class="text-muted" />
        </b-input-group-prepend>
        <b-form-input v-model="searchQuery" placeholder="Cerca una conversa" />
      </b-input-group>
    </div>

    <!-- CONVERSATIONS -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scroll-area"
    >
      <!-- Conversations Title -->
      <h4 class="ml-1 mt-1">Converses</h4>

      <!-- Conversations List -->
      <ul class="conversations-list pl-0 mb-0">
        <conversations-list-item
          v-for="conversation in filteredConversations"
          :key="conversation.uuid"
          :conversation="conversation"
          @selected="selectConversation($event)"
          :class="{ selected: isSelected(conversation) }"
        />
      </ul>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import { BInputGroup, BInputGroupPrepend, BFormInput } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ConversationsListItem from "./ConversationsListItem.vue";

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    VuePerfectScrollbar,
    ConversationsListItem,
  },
  data() {
    return {
      searchQuery: null,
      selectedConversation: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
    };
  },
  computed: {
    conversations() {
      return this.$store.getters["whatsapp/conversations"];
    },
    filteredConversations() {
      if (!this.searchQuery) {
        return this.conversations;
      } else {
        return this.conversations.filter((conversation) => {
          // Check if phoneNumber or displayName contains the search query
          const lowerCaseSearchQuery = this.searchQuery.toLowerCase();
          return (
            conversation.phoneNumber
              ?.toLowerCase()
              .includes(lowerCaseSearchQuery) ||
            conversation.displayName
              ?.toLowerCase()
              .includes(lowerCaseSearchQuery)
          );
        });
      }
    },
  },
  methods: {
    selectConversation(conversation) {
      this.selectedConversation = conversation;
      this.$emit("conversation-selected", conversation);
    },
    isSelected(conversation) {
      return this.selectedConversation?.uuid === conversation.uuid;
    },
  },
};
</script>

<style lang="scss" scoped>
.searchbar {
  height: 64px;
}

.scroll-area {
  position: relative;
  margin: auto;
  height: calc(100vh - 233px);

  .conversations-list {
    list-style: none;
    .selected {
      background-color: #ffcc006a;
    }
  }
}
</style>
