<template>
  <vue-perfect-scrollbar
    :settings="perfectScrollbarSettings"
    class="scroll-area"
    ref="scrollArea"
  >
    <div class="messages px-1 py-2">
      <conversation-message
        v-for="(message, index) in messages"
        :key="`message-${index}`"
        :message="message"
        :right="conversation.phoneNumber !== message.phoneNumber"
        :image="
          conversation.phoneNumber !== message.phoneNumber ? appLogoImage : null
        "
        :class="{ 'mb-2': index + 1 < messages.length }"
        :ref="`message-${index}`"
      />
    </div>
  </vue-perfect-scrollbar>
</template>

<script>
import { $themeConfig } from "@themeConfig";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ConversationMessage from "./ConversationMessage.vue";

export default {
  components: {
    ConversationMessage,
    VuePerfectScrollbar,
  },
  props: {
    conversation: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
    };
  },
  computed: {
    appLogoImage() {
      return $themeConfig.app.appLogoImage;
    },
    conversationName() {
      if (!this.conversation?.phoneNumber) return "Conversa";
      return this.conversation.displayName || this.conversation.phoneNumber;
    },
    messages() {
      return this.conversation?.messages || [];
    },
  },
  methods: {
    scrollToLastMessage() {
      if (!this.messages?.length) return;
      this.$nextTick(() => {
        this.$refs.scrollArea.$el.scrollTop =
          this.$refs.scrollArea.$el.scrollHeight;
        this.$refs.scrollArea.update();
      });
    },
  },
  mounted() {
    this.scrollToLastMessage();
  },
  watch: {
    messages(messages) {
      if (messages.length) {
        this.scrollToLastMessage();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-area {
  position: relative;
  margin: auto;
  height: calc(100vh - 233px);
}
</style>
