<template>
  <li class="p-1 d-flex" @click="$emit('selected', conversation)">
    <div class="d-flex align-items-center">
      <b-avatar class="mr-1" />
    </div>
    <div class="d-flex flex-column justify-content-between flex-grow-1">
      <h5 class="mb-0">
        {{ conversationName }}
      </h5>
      <div class="card-text text-truncate d-flex align-items-center">
        <feather-icon
          v-if="showCheck"
          :class="{ 'text-info': isRead }"
          icon="CheckIcon"
          size="12"
        />
        <feather-icon
          v-if="showDoubleCheck"
          class="double-check"
          :class="{ 'text-info': isRead }"
          icon="CheckIcon"
          size="12"
        />
        <feather-icon
          v-if="showError"
          class="text-danger"
          icon="AlertCircleIcon"
          size="12"
        />
        <span class="ml-25">
          {{ conversationLastMessageExtract }}
        </span>
      </div>
    </div>
    <div class="text-nowrap">
      <div v-if="conversationLastMessageDate">
        <small class="float-right mb-25 ml-25 chat-time">
          {{ conversationLastMessageDate }}
        </small>
      </div>
      <b-badge
        v-if="unreadMessagesCount > 0"
        pill
        variant="primary"
        class="float-right"
      >
        {{ unreadMessagesCount }}
      </b-badge>
    </div>
  </li>
</template>

<script>
import { BAvatar, BBadge } from "bootstrap-vue";
import { formatDateStringToDate } from "@/utils/formatters";

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    conversation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    conversationName() {
      if (!this.conversation?.phoneNumber) return "Conversa";
      return this.conversation.displayName || this.conversation.phoneNumber;
    },
    messages() {
      return this.conversation?.messages || [];
    },
    unreadMessages() {
      return 0;
      // TODO: add unread funcionality
      // if (!this.messages.length) return [];
      // return this.messages.filter((message) => {
      //   const messageStatus = message.status || null;
      //   if (!messageStatus) return false;
      //   return message.status !== "READ";
      // });
    },
    unreadMessagesCount() {
      return this.unreadMessages.length || 0;
    },
    conversationLastMessage() {
      const messagesCount = this.messages.length || 0;
      if (!messagesCount) return null;
      return this.messages[messagesCount - 1];
    },
    conversationLastMessageFailed() {
      return this.conversationLastMessage?.status === "FAILED" || false;
    },
    conversationLastMessageExtract() {
      const content = this.conversationLastMessage?.content || null;
      const truncatedContent =
        content && content.length > 40 ? content.slice(0, 30) + "..." : content;
      return truncatedContent || "Sense missatges";
    },
    conversationLastMessageDate() {
      const updateDate = this.conversation?.updatedAt || null;
      if (!updateDate) return null;
      return formatDateStringToDate(updateDate, this.$i18n.locale);
    },
    showCheck() {
      return ["SENT", "DELIVERED", "READ"].includes(
        this.conversationLastMessage?.status
      );
    },
    showDoubleCheck() {
      return ["DELIVERED", "READ"].includes(
        this.conversationLastMessage?.status
      );
    },
    isRead() {
      return this.conversationLastMessage?.status === "READ";
    },
    showError() {
      return ["FAILED"].includes(this.conversationLastMessage?.status);
    },
  },
};
</script>

<style lang="scss" scoped>
li {
  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
}
.double-check {
  margin-left: -7px;
}
</style>
