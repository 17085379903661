<template>
  <b-card class="whatsapp-wrapper mb-0" no-body>
    <b-row class="px-1">
      <b-col cols="4" class="conversations-list-wrapper border-right p-0">
        <conversations-list @conversation-selected="onConversationSelected" />
      </b-col>
      <b-col
        cols="8"
        class="conversation-details-wrapper p-0 conversation-container"
      >
        <no-conversation-selected v-if="!selectedConversation" />
        <template v-else>
          <conversation-header :conversation="selectedConversation" />
          <conversation-details :conversation="selectedConversation" />
        </template>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import ConversationsList from "./components/ConversationsList.vue";
import ConversationHeader from "./components/ConversationHeader.vue";
import ConversationDetails from "./components/ConversationDetails.vue";
import NoConversationSelected from "./components/NoConversationSelected.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    ConversationsList,
    NoConversationSelected,
    ConversationHeader,
    ConversationDetails,
  },
  data() {
    return {
      selectedConversation: null,
    };
  },
  created() {
    this.fetchConversations();
  },
  methods: {
    fetchConversations() {
      this.$store.dispatch("whatsapp/fetchConversations");
    },
    onConversationSelected(conversation) {
      this.selectedConversation = conversation;
    },
  },
};
</script>

<style lang="scss" scopped>
.whatsapp-wrapper {
  height: calc(100vh - 12rem);
  overflow: hidden;

  .conversation-container {
    background-color: #fafafa;
  }
}
</style>
