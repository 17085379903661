<template>
  <!-- HEADER -->
  <div class="border-bottom p-1 bg-white">
    <div class="d-flex align-items-center">
      <b-avatar class="mr-1" />
      <h5 class="mb-0">
        {{ conversationName }}
      </h5>
    </div>
  </div>
</template>

<script>
import { BAvatar } from "bootstrap-vue";

export default {
  components: {
    BAvatar,
  },
  props: {
    conversation: {
      type: Object,
      required: false,
    },
  },
  computed: {
    conversationName() {
      if (!this.conversation?.phoneNumber) return "Conversa";
      return this.conversation.displayName || this.conversation.phoneNumber;
    },
  },
};
</script>
